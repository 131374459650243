.account_icon {
  color: white;
  transition: color 0.7s ease;
}

.account_icon:hover circle,
.account_icon:hover path {
  stroke: white;
}

.account_icon:hover {
  color: #e81e61;
}
