.toast_wrapper {
  background: white;
  border-radius: 12px;
}

.success_body {
  color: white;
  background: #10bb691a;
  padding: 15px !important;
}

.info_body {
  background: #2c75ff1a;
  padding: 15px !important;
}

.error_body {
  background: #ff42491a;
  padding: 15px !important;
}

.warning_body {
  background: #ff912c1a;
  padding: 15px !important;
}

.toast_wrapper button {
  margin: auto !important;
}
