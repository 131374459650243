/* @import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,800;1,900&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

/* tooltip design */

.tippy-box {
  border-radius: 10px !important;
  max-width: 500px !important;
}

/* calender  start */
.Calendar__day {
  color: #0a101a !important;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: var(--cl-color-primary);
  color: #fff !important;
}

.custom-today-day {
  font-weight: normal !important;
  color: #0a101a !important;
}

.custom-today-day::after {
  visibility: hidden;
  /* hide small border under the text */
}

.Calendar__monthArrow {
  border-radius: 50%;
  transition: var(--animation-duration) transform;
  pointer-events: none;
  background-repeat: no-repeat;
  display: block;
  width: 6px !important;
  height: 1.7em;
  background-image: url("/assets/icons/calender_back.svg") !important;

  background-size: 100% 100%;
}

.Calendar__monthArrowWrapper.-right {
  transform: rotate(2deg) !important;
}

.Calendar__monthArrowWrapper.-left {
  transform: rotate(182deg) !important;
}

.Calendar__weekDays {
  color: #bebebe !important;
  font-size: 14px !important;
}

/* calender  end */

/* toast  */

.Toastify__toast {
  position: relative;
  min-height: auto;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 0px !important;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  cursor: default;
  direction: ltr;
  z-index: 0;
  min-width: 330px;
}

#react-select-search-box-input {
  box-shadow: none;
}

.banner-carousel .carousel-slider {
  border-radius: 16px;
}

@media only screen and (max-width: 1024px) {
  .banner-carousel .carousel-slider {
    border-radius: 4px;
  }
}

.grecaptcha-badge {
  display: none !important;
}

/* start of admin text editor */
.admin-text-editor *:not(table):not(td):not(tr):not(tbody):not(img) {
  all: revert;
}

.top-banner a {
  text-decoration: underline !important;
}

.admin-text-editor li {
  white-space: normal !important;
}

.admin-text-editor table[Style] {
  border-width: revert;
}

.admin-text-editor table * {
  border-color: inherit;
  border-width: inherit;
}

.customScrollBar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.customScrollBar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background-color: #e81e61;
  border: 1px solid #f1f1f1;
}

.filterScrollBar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 30px;
}

.filterScrollBar::-webkit-scrollbar {
  width: 4px;
}

.filterScrollBar::-webkit-scrollbar-thumb {
  background-color: rgba(10, 16, 26, 0.3);
  height: 90px !important;
  border-radius: 30px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-multi-carousel-dot button {
  background: #bebebe !important;
  border: none !important;
  width: 8px !important;
  height: 8px !important;
}

.react-multi-carousel-dot--active button {
  background: #e81e61 !important;
}

.pet-profile-bg {
  background: transparent linear-gradient(180deg, #0a101a00 0%, #0a101ae6 100%)
    0% 0% no-repeat padding-box;
}

.pet-quote-bg {
  background-image: url("/assets/pet-tag/bg-quote.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.showcase-background {
  background: url("/assets/pet-tag/showcase-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pet-tag-hero-bg {
  background: url("/assets/pet-tag/hero/bg-grid.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-shadow {
  text-shadow: 0px 6px 12px rgba(20, 11, 48, 0.25);
}

.overflow-hidden {
  overflow: hidden;
}

.bg-deal {
  background: url("/assets/pet-tag/bg-deal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
