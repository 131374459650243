.sidebarHideTransition {
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  animation: 0.4s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
